import React from 'react'
import { graphql } from 'gatsby'
import Article, { VARIANT_OPENER, VARIANT_TEXT_INTENDED } from '../modules/Article.js'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components2/Layout'
import SEO from '../components/SEO'

const Page = ({ data }) => {
  const postNode = {
    title: `Fassdichte - ${config.siteTitle}`,
  }

  const block1 = {
    leading: 5,
    laces: true,
    variant: VARIANT_OPENER,
    headline: `Fassdichte und Kelterlacke`,
    lead: `Fassdichte ist ein besonderes Edelwachs das bei uns noch in Handarbeit gefertigt wird. Schon seit über 70 Jahren stellen wir sie in unseren Fabrikationsräumen her.`,
    image: {
      alt: 'Placeholder',
      fluid: data.opener.childImageSharp.fluid
    },
  }
  
  const block2 = {
    dropcap: true,
    variant: VARIANT_TEXT_INTENDED,
    copytext: `<p>Es findet seine Anwendung vornehmlich in der Wein-, Bier- und Spirituosenwirtschaft zum Verschließen von Fugen und Fasstüren. Sie verhindert Flüssigkeitsverlust aus dem Holzfass.
    Bestehend aus verschiedenen Spezialwachsen, zeichnet sie sich durch völlige Geschmacks- und Geruchsfreiheit aus.</p>
    <p></p>
    <p>Hergestellt werden auch Küferlacke wie Kelterlack und Fasslack für Wein, Most, Saftpressen und Holzfässer.</p>`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Fassdichte - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="fassdichte" customTitle />

      <div>
        <article>
          <Article {...block1}></Article>
          <Article {...block2}></Article>
        </article>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    opener: file(relativePath: { eq: "fassdichte-opener.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
